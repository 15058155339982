<template>

    <Album />

    <!--
    <div class="col-12">
        <Album />
    </div>
-->
</template>

<script>
// @ is an alias to /src
import Album from '@/components/bootstrapDashboard/Album.vue'

export default {
    name: 'AlbumView',
    components: {
        Album
    }
}
</script>

<style>
    @import '../../assets/css/album.css';
</style>
